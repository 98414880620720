import { FormHelperText, Link } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Translation } from 'application/translation/translationSlice';
import * as React from 'react';
import { useMemo } from 'react';
import ColoredIconButton from '../../../common/ColoredIconButton/ColoredIconButton';
import AppendableAutocomplete from '../../../common/Form/AppendableAutocomplete';

type BnclOfferOptionsProps = {
    formik: any;
    name: string;
    label: string;
    disabled: boolean;
    options: Translation[];
    usedOptionKeys: string[];
};

type OptionProps = {
    value: string;
    label: string;
    options: Translation[];
    onChange: (val: string) => void;
    error: boolean;
    disabled: boolean;
    showDelete: boolean;
    onDelete: () => void;
    name: string;
    usedOptionKeys: string[];
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addOptionButton: {
            marginTop: theme.spacing(1),
            float: 'right',
        },
        optionItem: {
            display: 'flex',
            marginTop: theme.spacing(2),
        },
        optionItemInput: {
            flex: '20 1 auto',
        },
        optionItemDeleteButton: {
            alignSelf: 'end',
            marginLeft: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

const Option = ({
    value,
    label,
    options,
    onChange,
    error,
    disabled,
    showDelete,
    onDelete,
    name,
    usedOptionKeys,
}: OptionProps) => {
    const classes = useStyles();

    const optionsToShow = useMemo(() => {
        const tr = options.filter(o => value === o.value || !usedOptionKeys.includes(o.key));
        return tr.map(item => ({ label: item.value, value: item.value }));
    }, [value, options, usedOptionKeys]);

    return (
        <div className={classes.optionItem}>
            <AppendableAutocomplete
                value={value}
                autocompleteProps={{
                    disabled,
                    className: classes.optionItemInput,
                }}
                onChange={onChange}
                textFieldProps={{
                    InputLabelProps: {
                        shrink: true,
                    },
                    label,
                    'data-cy': `Bncl-OfferForm-${name}`,
                    error,
                }}
                options={optionsToShow}
            />
            {showDelete && (
                <ColoredIconButton
                    size="small"
                    variant="outlined"
                    onClick={onDelete}
                    color="primary"
                    className={classes.optionItemDeleteButton}
                    data-cy={`Bncl-OfferForm-${name}-Delete-Button`}
                >
                    <DeleteIcon fontSize="small" />
                </ColoredIconButton>
            )}
        </div>
    );
};

const OptionsEditor = ({
    formik,
    name,
    label,
    disabled,
    options,
    usedOptionKeys,
}: BnclOfferOptionsProps): React.ReactElement => {
    const classes = useStyles();

    const hasError = !!formik.touched[name] && !!formik.errors[name];

    const uniqueOptions = useMemo(() => {
        const unique = options.reduce(
            (acc, curr) => ({ ...acc, [curr.value]: curr }),
            {} as Record<string, Translation>,
        );
        return Object.values(unique);
    }, [options]);

    return (
        <>
            {formik.values[name].map((item, index) => (
                <Option
                    key={index}
                    value={item}
                    label={index === 0 ? label : ''}
                    options={uniqueOptions}
                    onChange={val => {
                        const values = [...formik.values[name]];
                        values[index] = val;
                        formik.setFieldValue(name, values);
                    }}
                    error={hasError}
                    disabled={disabled}
                    showDelete={formik.values[name].length > 1 && !disabled}
                    onDelete={() => {
                        const options = [...formik.values[name]];
                        options.splice(index, 1);
                        formik.setFieldValue(name, options);
                    }}
                    name={name}
                    usedOptionKeys={usedOptionKeys}
                />
            ))}

            {hasError && (
                <FormHelperText error={hasError} data-cy={`Bncl-OfferForm-${name}-ErrorText`}>
                    {formik.errors[name]}
                </FormHelperText>
            )}
            {!disabled && (
                <Link
                    component="button"
                    className={classes.addOptionButton}
                    type="button"
                    variant="body2"
                    onClick={() => {
                        formik.setFieldValue(name, [...formik.values[name], '']);
                    }}
                    data-cy={`Bncl-OfferForm-${name}-Add-Button`}
                >
                    + ADD
                </Link>
            )}
        </>
    );
};

export default OptionsEditor;
