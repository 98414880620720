import { Option, Vendor as VendorCatalog } from '@albelli/ecom-promotions-editor';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Theme,
    Tooltip,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { OfferDiscountDetails } from 'apis/bnclCampaign';
import { FormikSelect } from 'components/common/Form/FormikSelect';
import { FormikErrors, useFormik } from 'formik';
import { buildDiscountDefinition, supportedPapTags } from 'helpers/bnclHelper';
import { isFinished } from 'helpers/requestStateHelper';
import _ from 'lodash';
import { Vendor } from 'models/vendor';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { getAppConfig } from 'services/configService';
import { useSelector } from 'store';

type OptionValue = {
    isFree: boolean;
    value: string;
};

type FormType = {
    papId: string;
    options: Record<string, OptionValue>;
    freeShipping: boolean;
};

export interface BnclDiscountConstructorState {
    vendor: Vendor;
    details?: OfferDiscountDetails;
    onChange: (form: FormType, code: string) => void;
    disabled?: boolean;
}

type PapOptionProps = {
    option: Option;
    onChange: (val: OptionValue) => void;
    value?: OptionValue;
    disabled?: boolean;
};

export interface BnclDiscountConstructorHandlers {
    /**
     * returns void if invalid
     */
    submit: () => Promise<FormType | void>;
    isModified: () => boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        papRow: {
            '& > .MuiFormControl-root': {
                width: 'calc(100% - 20px)',
            },
            '& > .MuiLink-root svg': {
                verticalAlign: 'bottom',
                marginBottom: '4px',
            },
        },
        optionBox: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            display: 'flex',
            alignItems: 'end',
        },
        optionInput: {
            minWidth: 160,
        },
    }),
);

const validate = (values: FormType) => {
    const errors: FormikErrors<FormType> = {};

    if (!values.papId) {
        errors.papId = 'Pap ID is required';
    }
    return errors;
};

export const PapOption = ({
    option,
    value = { isFree: false, value: '' },
    disabled,
    onChange,
}: PapOptionProps): React.ReactElement => {
    const classes = useStyles();
    return (
        <Box className={classes.optionBox}>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={value.isFree}
                        onChange={(ev, isFree) => onChange({ ...value, isFree })}
                        disabled={disabled}
                        name={`free${option.id}`}
                        data-cy={`Bncl-OfferForm-FreePapOption-${option.id}`}
                    />
                }
                label={`Free ${option.id}`}
            />
            {value.isFree && option.type === 'LIST' && (
                <FormControl className={classes.optionInput}>
                    <InputLabel shrink={true}>Free For</InputLabel>
                    <Select
                        name="listVal"
                        value={value.value}
                        onChange={ev => onChange({ ...value, value: ev.target.value as string })}
                        disabled={disabled}
                        displayEmpty
                        data-cy={`Bncl-OfferForm-SelectPapOption-${option.id}`}
                    >
                        <MenuItem value="">
                            <em>Any</em>
                        </MenuItem>
                        {option.values.map(({ id, aliases }) => (
                            <MenuItem key={id} value={id}>
                                {id} - {aliases[0]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {value.isFree && option.type === 'INT' && (
                <TextField
                    name={`${option.id}NumVal`}
                    className={classes.optionInput}
                    label="Amount"
                    type="number"
                    inputProps={{ min: 0, max: 96 }}
                    value={value.value}
                    disabled={disabled}
                    InputLabelProps={{ shrink: true, required: true }}
                    onChange={ev => onChange({ ...value, value: String(ev.target.value) })}
                    data-cy={`Bncl-OfferForm-NumberPapOption-${option.id}`}
                />
            )}
        </Box>
    );
};

const knownProductOptions = [
    'CoverType',
    'ExtraPages',
    'Glossy',
    'CoverText',
    'LayFlat',
    'PremiumLayFlat',
    'PaperType',
];

const BnclDiscountConstructor = (
    { details, onChange, disabled, vendor }: BnclDiscountConstructorState,
    ref,
): React.ReactElement => {
    const classes = useStyles();
    const catalog = useSelector(state => state.promotionCatalog);

    const formik = useFormik<FormType>({
        initialValues: {
            papId: '',
            options: {},
            freeShipping: false,
        },
        validate,
        onSubmit: async _ => _,
    });

    useEffect(() => {
        if (!details) return;
        formik.setValues({ ...details });
    }, [details]);

    useImperativeHandle(
        ref,
        (): BnclDiscountConstructorHandlers => ({
            submit: async () => {
                const vals = (await formik.submitForm()) as FormType | null;
                if (!vals) return;
                return vals;
            },
            isModified: () => {
                const initialValues = details ? details : formik.initialValues;
                return !_.isEqual(initialValues, formik.values);
            },
        }),
    );

    const vendorName = vendor.name === 'onskefoto.se' ? 'albelli.se' : vendor.name;

    const vendorCatalog = useMemo(() => {
        if (!catalog.payload) return;
        return catalog.payload.vendors.find(v => v.id === vendorName);
    }, [vendor, catalog.payload]);

    const papSelectOpts = useMemo(() => {
        if (!vendorCatalog) return [];
        const items = vendorCatalog.products
            .filter(p => Object.values(supportedPapTags).find(t => p.tags.includes(t)))
            .map(p => ({
                value: p.id,
                label: `${p.id} - ${p.tags[0]}`,
            }));
        items.sort((a, b) => (a.value > b.value ? 1 : -1));
        return items;
    }, [vendorCatalog]);

    const productOptions = useMemo(() => {
        if (!formik.values.papId || !vendorCatalog) return [];

        const list = vendorCatalog.products.find(p => p.id === formik.values.papId)?.options || [];
        return list.filter(o => knownProductOptions.includes(o.id));
    }, [formik.values.papId, vendorCatalog]);

    const onPapChange = papId => {
        const list = (vendorCatalog as VendorCatalog).products.find(p => p.id === papId)?.options || [];
        const options = list.filter(o => knownProductOptions.includes(o.id));

        const formState: FormType = {
            papId,
            options: options.reduce(
                (acc, o) => ({
                    ...acc,
                    [o.id]: {
                        isFree: false,
                        value: '',
                    },
                }),
                {} as Record<string, OptionValue>,
            ),
            freeShipping: false,
        };
        formik.setValues(formState);
        onChange(formState, buildDiscountDefinition(formState, vendorName));
    };
    const setFieldValue = (field: string, value: any) => {
        const formState: FormType = {
            ...formik.values,
            [field]: value,
        };

        formState.options = productOptions.reduce(
            (acc, o) => ({
                ...acc,
                // need to re-validate option existence, for cases when this offer was duplicated from old campaign
                // in such case, form state can have options that have been removed from the product, and we need clear those
                [o.id]: formState.options[o.id] || {
                    isFree: false,
                    value: '',
                },
            }),
            {} as Record<string, OptionValue>,
        );

        formik.setValues(formState);
        onChange(formState, buildDiscountDefinition(formState, vendorName));
    };

    if (isFinished(catalog))
        return (
            <div data-cy="Bncl-OfferForm-DiscountConstructor">
                <Box className={classes.papRow}>
                    <FormikSelect
                        label="Pap Id"
                        name="papId"
                        options={papSelectOpts}
                        disabled={disabled}
                        formik={formik}
                        onChange={onPapChange}
                        data-cy="Bncl-OfferForm-PapId"
                    />

                    {formik.values.papId && (
                        <Tooltip title="Pap extras pricing" placement="top">
                            <Link
                                href={
                                    new URL(
                                        `/Articles/${formik.values.papId}/Extras?channel=${vendorName}`,
                                        getAppConfig().ProductPricingCatalogApiUrl,
                                    ).href
                                }
                                target="_blank"
                            >
                                <LinkIcon />
                            </Link>
                        </Tooltip>
                    )}
                </Box>
                {productOptions.map(o => (
                    <PapOption
                        key={o.id}
                        option={o}
                        disabled={disabled}
                        value={formik.values.options[o.id]}
                        onChange={val => {
                            setFieldValue('options', {
                                ...formik.values.options,
                                [o.id]: val,
                            });
                        }}
                    />
                ))}
                {formik.values.papId && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                disabled={disabled}
                                checked={formik.values.freeShipping}
                                onChange={(ev, isFree) => setFieldValue('freeShipping', isFree)}
                                name="freeShipping"
                                data-cy="Bncl-OfferForm-FreeShipping"
                            />
                        }
                        label="Free Shipping"
                    />
                )}
            </div>
        );
    else return <div />;
};

export default forwardRef(BnclDiscountConstructor);
