import * as React from 'react';
import { Link, Typography } from '@material-ui/core';
import { getAppConfig } from 'services/configService';

export type LookerLinkProps = {
    attributions: string[];
    disabled?: boolean;
};

export default function LookerLink({ attributions, disabled = false, ...props }: LookerLinkProps) {
    if (disabled) {
        return (
            <Typography variant="body2" component="span" color="textSecondary" data-cy="Bncl-LookerLinkDisabled">
                View results
            </Typography>
        );
    }

    const resultingUrl = new URL('/dashboards/766', getAppConfig().LookerUrl);
    resultingUrl.searchParams.append('Redeemed+Reference+Date', '');
    resultingUrl.searchParams.append(
        'Discount+Name',
        attributions
            .map(attr => {
                return `"${attr}"`;
            })
            .join(','),
    );
    resultingUrl.searchParams.append('Discount+Campaign', '%bncl%');
    resultingUrl.searchParams.append('Discount+channel', '');
    resultingUrl.searchParams.append('Voucher+Sales+Date', '');

    return (
        <Link
            {...props}
            underline="always"
            target="_blank"
            rel="noopener"
            data-cy="Bncl-LookerLink"
            href={resultingUrl.toString()}
        >
            View results
        </Link>
    );
}
